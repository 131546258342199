import { Center, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "@remix-run/react";
import { Button } from "~/ui/tracked/form";

export function NotFound(): JSX.Element {
  const navigate = useNavigate();
  return (
    <Center mt="lg">
      <SimpleGrid gap="md">
        <SimpleGrid gap="sm">
          <Heading>Page Not Found</Heading>
          <Text>The page you&apos;re looking for can&apos;t be found.</Text>
        </SimpleGrid>

        <Flex gap="sm">
          <Button
            eventName="Go Home"
            eventProperties={{ from: "Not Found" }}
            onClick={(): void => navigate("/")}
            size="sm"
            variant="outline"
            w="max-content"
          >
            Go Home
          </Button>

          <Button
            colorScheme="rose"
            eventName="Login"
            eventProperties={{ from: "Not Found" }}
            onClick={(): void => navigate("/login")}
            size="sm"
            w="max-content"
          >
            Login / Sign Up
          </Button>
        </Flex>
      </SimpleGrid>
    </Center>
  );
}

export default NotFound;
